import './App.css';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter } from "react-router-dom";
import Layout from './components/Layout/Layout';


function App() {
  return (
    <>
      <BrowserRouter>
        <Layout />
        <CookieConsent
            location="bottom"
            buttonText="Bestätigen"
            cookieName="myAwesomeCookieName2"
            style={{ background: "var(--mainColor)", color: "var(--textColor)", border: "1px solid var(--textColor)" }}
            buttonStyle={{ color: "var(--textColor)", background: "var(--mainColor)", border: "1px solid var(--textColor)", fontSize: "13px" }}
            expires={15}
            >
            Diese Website verwendet Cookies und Analytics, um die Benutzererfahrung zu verbessern.{" "}
        </CookieConsent>
      </BrowserRouter>
    </>
  );
}

export default App;
