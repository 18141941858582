import React from 'react'
import "../styles/hero.css"
import Isyan from "../../images/2-1.png"
import IsyanPlate from "../../images/isyanplate.png"
import Carousel from "./Carousel"

import { FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';

const Hero = () => {
  return (
    <div>
      <div className="container container-bottom">
        <section>
          <img src={Isyan} alt="Isyanworld Header" />
            <h1>Isyanworld</h1>
            <p>Alle verlinkungen zu den offiziellen Isyanworld® Kanälen findet ihr hier</p>

          <div className="sec-top">
            <div className="">
              <div className="sec-icons">
                <a href="https://www.instagram.com/isyanworld/" target="_blank" rel="noreferrer">< FaInstagram /></a>
                <a href="https://www.tiktok.com/@cemonym?lang=de-DE" target="_blank" rel="noreferrer">< FaTiktok /></a>
                <a href="https://www.youtube.com/channel/UCDxcJ5XO2boDgW42WyM0AIw" target="_blank" rel="noreferrer">< FaYoutube /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-three">
          <h2>Isyan Plates</h2>
          <p>Merch von Fans für Fans</p>
          <img src={IsyanPlate} alt="Isyanplates" />
          <div className="sec-top">
            <div className="sec-icon">
              <div className="sec-icons">
                <a href="https://www.instagram.com/isyanplates/" target="_blank" rel="noreferrer">< FaInstagram /></a>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-two">
          <h2>Isyanmood</h2>
          <p>Wöchentlich wird unsere Playlist auf Spotify aktualisiert um Euch auf dem neusten Stand zu halten.</p>
          <iframe title="isyanmood" loading="eager" src="https://open.spotify.com/embed/playlist/7MTkkMY0Qk6mLxxPBR5a4b?utm_source=generator&theme=0" width="100%" height="500" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
          <div className="sec-top">
            <a className="sec-spot" href="https://open.spotify.com/playlist/7MTkkMY0Qk6mLxxPBR5a4b?si=a511d2ee9e99467c" target="_blank" rel="noreferrer">In Spotify öffnen</a>
          </div>
        </section>

        <section>
          <Carousel />
        </section>
      </div>
    </div>
  )
}

export default Hero