import "./../styles/section.css"
const Section = ({
  children,
  id,
  className,
  title,
  subtitle,
  description,
  border,
}) => {
  return (
    <div
      id={id}
      className={`${className} ${border ? "" : "noBorder"} ${
        "container"
      }`}
    >
      {title && (
        <div className="title">
          <h3>
            <span>{subtitle}</span>
          </h3>
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </div>
      )}
      {title ? <div className="children">{children}</div> : children}
    </div>
  );
};

Section.defaultProps = {
  border: true,
};

export default Section;
