import React from 'react'
import "./../components/styles/datenschutz.css"

const Datenschutz = () => {
  return (
    <div className="container container-bottom datenschutz">
      <section>
        <h1>Datenschutz</h1>
        <p>
        Datenschutzrichtlinie
<br/><br/>
Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und möchten Ihnen auf dieser Seite einen transparenten Überblick darüber geben, wie wir mit Ihren Daten umgehen, wenn Sie unsere Website besuchen.
<br/><br/><br/>
Datenverarbeitung durch Google Analytics
<br/><br/>
Wir verwenden Google Analytics, um Informationen darüber zu sammeln, wie unsere Website genutzt wird. Google Analytics ist ein Webanalysedienst, der von Google Inc. bereitgestellt wird. Die von Google Analytics gesammelten Daten werden genutzt, um die Nutzung unserer Website zu analysieren und Berichte darüber zu erstellen. Diese Berichte helfen uns, die Website zu verbessern und unseren Besuchern ein besseres Nutzererlebnis zu bieten.
<br/><br/>
Google Analytics setzt Cookies auf Ihrem Gerät, um Informationen wie Ihre IP-Adresse, den von Ihnen verwendeten Browser, die von Ihnen aufgerufenen Seiten und die von Ihnen getätigten Interaktionen auf unserer Website zu sammeln. Diese Informationen werden an Google übermittelt und dort gespeichert.
<br/><br/>
Wir nutzen Google Analytics auf Grundlage unseres berechtigten Interesses, die Nutzung unserer Website zu analysieren und zu verbessern. Die Verarbeitung Ihrer Daten erfolgt somit auf Grundlage von Artikel 6 Absatz 1 lit. f DSGVO.
<br/><br/>
Sie können die Verwendung von Cookies durch Google Analytics deaktivieren, indem Sie das Browser-Add-on zur Deaktivierung von Google Analytics installieren. Das Add-on informiert das JavaScript (ga.js) von Google Analytics, dass keine Informationen über den Website-Besuch an Google Analytics übermittelt werden sollen.
<br/><br/>
Weitere Informationen zur Verwendung von Daten durch Google Analytics finden Sie in den Datenschutzrichtlinien von Google unter https://policies.google.com/privacy.
<br/><br/><br/>
Ihre Rechte
<br/><br/>
Sie haben das Recht, jederzeit Auskunft über die personenbezogenen Daten zu verlangen, die wir über Sie gespeichert haben. Sie haben auch das Recht, die Berichtigung oder Löschung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie Fragen oder Anliegen bezüglich der Verarbeitung Ihrer personenbezogenen Daten haben, können Sie sich jederzeit an denn Webmaster wenden.
<br/><br/><br/>
Änderungen dieser Datenschutzrichtlinie
<br/><br/>
Wir behalten uns das Recht vor, diese Datenschutzrichtlinie jederzeit zu ändern. Wenn wir Änderungen an dieser Richtlinie vornehmen, werden wir die Änderungen auf unserer Website veröffentlichen. Bitte überprüfen Sie diese Seite regelmäßig, um sicherzustellen, dass Sie über unsere Datenschutzpraktiken auf dem Laufenden sind.
        </p>
      </section>
    </div>
  )
}

export default Datenschutz