import React, { useRef, useEffect } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { NavigationType, useLocation, useNavigationType } from "react-router-dom";

const nav__links = [
  {
    path: "/startseite",
    display: "Startseite",
  },
  {
    path: "/cemonym",
    display: "Cemonym",
  },
  {
    path: "/cemo",
    display: "Cemo",
  },
  {
    path: "/seronym",
    display: "Seronym",
  },
];

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const headerFunc = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      headerRef.current.classList.add("header__shrink");
    } else {
      headerRef.current.classList.remove("header__shrink");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", headerFunc);

    return () => window.removeEventListener("scroll", headerFunc);
  }, []);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  const closeMenu = () => menuRef.current.classList.remove("menu__active");

  const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
  };

  const useScrollToTop = () => {
    const { pathname } = useLocation();
    const isPop = useBackButton();
    const scrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
      scrollToTop();
    }, [pathname, isPop]);

    useEffect(() => {
      window.addEventListener("beforeunload", scrollToTop);
      return () => {
        window.removeEventListener("beforeunload", scrollToTop);
      };
    }, []);
  };

  useScrollToTop();

  return (
    <header className="header" ref={headerRef}>
      <div className="container1">
        <div className="nav__wrapper">
          <div className="logo" to="startseite">
            <Link to="/startseite"><h2>Isyanworld</h2></Link>
          </div>
          <div className="navigation" ref={menuRef}>
            <ul className="menu">
              {nav__links.map((item, index) => (
                <li className="menu__item" key={index}>
                  <Link className="menu__link" to={item.path} onClick={closeMenu}>
                    {item.display}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <span className="mobile__menu" onClick={toggleMenu}>
            <i className="ri-menu-line"></i>
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
