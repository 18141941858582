import React from 'react'
import "./../components/styles/cemonym.css"
import ikiDost from "./../images/ikiDost.jpg"

const Cemonym = () => {
  return (
    <div className="container container-bottom">
        <section className="sec-three">
            <h1>Das Interview</h1>
          <iframe loading="eager" width="100%" height="500" src="https://www.youtube.com/embed/VfoIqPBWdHc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </section>

        <section className="sec-three">
            <h2>Neustes Album</h2>
            <p>Klicke um jetzt anzuhören</p>
            <div className="album-top">
                <div className="album">
                    <a href="http://recordjet.promo.li/ikiDost" alt="ikiDost" target="_blank" rel="noreferrer"><img src={ikiDost} alt="ikiDost" /></a>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Cemonym