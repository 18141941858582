import React from "react";
import Sect from "./Sec";
import "./../styles/carousel.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { RiDoubleQuotesL } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa";

const Client = ({country, comment, name, profession, kunstler, url}) => {
  return (
    <div className="sec-thre">
      <div className="client">
        <div className="info">
          {/* Country */}
          <div className="country">
            <div className="svg">
              <RiDoubleQuotesL />
            </div>
            <span>{country}</span>
          </div>
          {/* comment */}
          <p className="comment">{comment}</p>
          {/* Künslter */}
          <p className="kunstler">{kunstler}</p>
          {/* name */}
          <div className="who">
            <span className="name">{name}</span>
            <span className="profession">{profession}</span>
          </div>
            <div className="actions">
              <a href={url} target="_blank" rel="noreferrer" className="contact">
                <h3>Ticket</h3>
                <FaArrowRight />
              </a>
            </div>
        </div>
      </div>
    </div>
  );
};



const Clients = () => {
  return (
    <Sect
      title="Events"
      id="info"
    >
      <Carousel emulateTouch showThumbs={false} showStatus={false}>
        <Client
          country={"TURKISH MUSIC NIGHT NÜRNBERG"}
          comment={"Eventdatum: 09.06.2023 Einlass: 21:00 Uhr - Beginn: 22:00 Uhr"}
          profession={"Regensburgerstrasse 334B / 90480 Nürnberg"}
          kunstler={""}
          name={"WON"}
          url={"https://gecetix.de/products/de/Konzerte/Turkish-Music-Night-Sancak-Won-Nuernberg.html"}
        />
        <Client
          country={"TURKISH MUSIC NIGHT FRANKFURT"}
          comment={"Eventdatum: 17.06.2023 Einlass: 21:00 Uhr - Beginn: 22:30 Uhr"}
          profession={"Carl-Benz-Straße 21 / 60386 Frankfurt am Main"}
          kunstler={""}
          name={"Zoom"}
          url={"https://gecetix.de/products/de/Konzerte/Turkish-Music-Night-Frankfurt-Zoom.html?fbclid=PAAaanKf8ACeF4UhprMqHmZqzQiFpCZtVdhiM9lVWqd2XGGdjA8rE1_jbWB90"}
        />
      </Carousel>
    </Sect>
  );
};

export default Clients;
