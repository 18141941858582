import React from 'react'
import './footer.css'
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container2">
        <div className="footer__wrapper">
          <Link className="menu__link" to="impressum">Impressum</Link>
          <Link className="menu__link" to="datenschutz">Datenschutz</Link>
          <Link className="menu__link" to="https://Q-Tech.dev" target="_blank">Webmaster</Link>
        </div>
        <p className="copyright">
          Copyright {year}, Isyanworld. All rights reserved.{" "}
        </p>
      </div>
    </footer>
  )
}

export default Footer