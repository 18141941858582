import React from 'react'

const Impressum = () => {
  return (
    <div className="container container-bottom datenschutz">
      <section>
        <h1>Impressum</h1>
        <p>
Angaben gemäß § 5 TMG:
<br/><br/>
Verantwortlich für den Inhalt dieser Webseite:<br/>
Name des Webmasters: Qualified Technologies Development<br/>
E-Mail-Adresse des Webmasters: Info@Q-Tech.dev<br/>
Sitz des Webmasters: 73728 Esslingen am Neckar<br/>
<br/><br/><br/>
Haftungsausschluss:
<br/><br/>
Die Inhalte dieser Webseite werden mit größtmöglicher Sorgfalt erstellt. Der Webmaster übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der Webseite erfolgt auf eigene Gefahr des Nutzers.
<br/><br/><br/>
Links zu externen Webseiten:
<br/><br/>
Diese Webseite kann Links zu externen Webseiten enthalten, auf deren Inhalte der Webmaster keinen Einfluss hat. Daher kann der Webmaster keine Gewähr für diese fremden Inhalte übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
<br/><br/><br/>
Urheberrecht:
<br/><br/>
Die durch den Webmaster erstellten Inhalte und Werke auf dieser Webseite unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des Webmasters. Downloads und Kopien dieser Webseite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
<br/><br/><br/>
Datenschutz:
<br/><br/>
Die Nutzung dieser Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf dieser Webseite personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adresse) erhoben werden, erfolgt dies stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
<br/><br/><br/>
Gerichtsstand:
<br/><br/>
Gerichtsstand für alle Rechtsstreitigkeiten im Zusammenhang mit dieser Webseite ist der Sitz des Webmasters.</p>
      </section>
    </div>
  )
}

export default Impressum