import React from 'react'
import Cemo1 from "./../images/Cemo.webp"

import { FaInstagram, FaSpotify } from 'react-icons/fa';
import { SiApplemusic } from 'react-icons/si';

const Cemo = () => {
  return (
    <div className="container container-bottom">
      <section className="sec-three">
        <h1>Cemo</h1>
        <img src={Cemo1} alt="Cemo" />

        <div className="sec-top sec-three">
          <div className="">
            <div className="sec-icons">
              <a href="https://www.instagram.com/orijinalcemo/" target="_blank" rel="noreferrer">< FaInstagram /></a>
              <a href="https://open.spotify.com/artist/4lhlZelIpwwtn2rRIZpuUf?si=NUD0Ov-xSRKNGDIImmbjvg&nd=1" target="_blank" rel="noreferrer">< FaSpotify /></a>
              <a href="https://music.apple.com/de/artist/cemo/1558698147" target="_blank" rel="noreferrer">< SiApplemusic /></a>
            </div>
          </div>
        </div>
        <p className="bio">[GER] Seit 2014 versucht sich der Rapper Cemo als Künstler zu etablieren. Seine Eltern zogen damals aus der Türkei nach Deutschland. Durch Freunde auf dem Schulhof begann der Deutsch-Türkische Rapper mit der Musik und hielt leidenschaftlich an diesem Traum fest.
<br/><br/>
Den ersten großen Erfolg bekam der Düsseldorfer Künstler mit dem Song „Sensiz“ zu spüren - ein Hit für alle Zuhörer, die eins mit tiefgründiger Musik sind. Mit mehreren Millionen Streams auf Spotify erreicht der junge Künstler eine neue Kategorie von Rapfans, welche sich seiner Musikrichtung widmen. Bis dato bleibt er seiner Linie treu und bereitet seinen Fans durch neue Musik, welche auch den aktuellsten Sound beinhalten, sehr viel Freude.
<br/><br/>
[TUR] Cemo 2014 yılından beri müzik çalışmalarıyla kendisine sanat dünyasında bir yer edinmeye çalışıyor. Ailesi, Türkiye'den Almanya'ya göç eden Cemo, okul avlusunda arkadaşları sayesinde başlayan müzik sevgisi Türk kökenli Alman rapçide bir tutkuya dönüştü.
<br/><br/>
Düsseldorflu sanatçı, ilk büyük başarısını duygusal müziğe sahip tüm dinleyiciler için hit olan “Sensiz” şarkısıyla yaşadı. Spotify'da birkaç milyon yayınla genç sanatçı, kendi müzik tarzıyla yeni bir hayran kitlesine ulaştı. Şimdiye kadar çizgisine sadık kalıp hayranlarına aktüel ses sistemiyle yeni müzikle büyük keyif veriyor.</p>
      </section>
    </div>
  )
}

export default Cemo