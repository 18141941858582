import {Routes, Route, Navigate } from'react-router-dom'

import Home from "./../pages/Startseite"
import Datenschutz from './../pages/Datenschutz';
import Impressum from './../pages/Impressum';
import Cemonym from './../pages/Cemonym';
import Seronym from './../pages/Seronym';
import Cemo from './../pages/Cemo';

const Routers = () => {

  return (
    <Routes>
        <Route exact path='/' element={<Navigate to="/startseite" />} />
        <Route path='startseite' element={ <Home /> } />
        <Route path='datenschutz' element={ <Datenschutz /> } />
        <Route path='cemonym' element={ <Cemonym /> } />
        <Route path='seronym' element={ <Seronym /> } />
        <Route path='cemo' element={ <Cemo /> } />
        <Route path='impressum' element={ <Impressum /> } />
    </Routes>
  )
}

export default Routers