import React from 'react'
import Seronym1 from "./../images/Seronym.webp"

import { FaInstagram, FaSpotify } from 'react-icons/fa';
import { SiApplemusic } from 'react-icons/si';

const Seronym = () => {
  return (
    <div className="container container-bottom">
      <section className="sec-three">
        <h1>Seronym</h1>
        <img src={Seronym1} alt="Seronym" />

        <div className="sec-top sec-three">
          <div className="">
            <div className="sec-icons">
              <a href="https://www.instagram.com/seronym.734/" target="_blank" rel="noreferrer">< FaInstagram /></a>
              <a href="https://open.spotify.com/artist/5wne1ZfV6mpGIcsAFksMUV?si=7gRKbYbvQaSCL2ux7DMmMg&nd=1" target="_blank" rel="noreferrer">< FaSpotify /></a>
              <a href="https://music.apple.com/de/artist/seronym/1440664924" target="_blank" rel="noreferrer">< SiApplemusic /></a>
            </div>
          </div>
        </div>
        <p className="bio">[GER] Angefangen mit Freestyle-Battles unter engen Freunden, stellte Seronym mit der Zeit fest, dass dieser Weg, den er einschlagen wollte, nicht mehr allein aus Spaß war. Geboren und aufgewachsen in Stuttgart - um genau zu sein in Esslingen - hatte er eine turbulente Jugend. Diese spiegelt sich in seinen Texten wieder und zieht Bezug auf die bittere Realität. Ein Jahr Gefängnisaufenthalt, etliche Schlägereien und viele weitere Probleme.
<br /><br />
Seinen ersten Erfolg konnte er mit dem Song „Sensiz“ feiern. Mit aktuell 100 Tsd. monatlichen Hörern auf Spotify erreicht er eine komplett neue Bandbreite an Deutschrap-Hörern. Größtenteils sind die Beats melancholisch gestaltet. Zentrale Themen sind dabei Liebe, Freiheit und Loyalität.
<br /><br /><br />
[TUR] Yakın arkadaş çevresi içinde Freestyle-Battles tarzıyla başlayan Seronym, zamanla bunun kendisi için sadece bir eğlence olmadığını anladı. Stuttgart’ta, tam olarak Esslingen´de, doğup büyüyen Seronym çok çalkantılı bir gençliğe sahipti. Rap yaparken söylediği sözler kendi geçmişini ve acı gerçeklerini yansıtıyor. Bir yıl hapis, birkaç kavga ve daha pek çok sorun hep hayatının merkezini oluşturmuştur.
<br /><br />
Seronym ilk büyük başarısını “Sensiz“ şarkısıyla elde etti. Spotify`da şu anda aylık 100 bin dinleyiciyle, tamamen yeni bir Alman rap dinleyici kitlesine ulaşıyor. Rap müziğindeki ritimleri çoğunlukla melankolik tarzda olup, sözleri ise sevgi, özgürlük ve sadakat gibi konular üzerine yazılmıştır.</p>
      </section>
    </div>
  )
}

export default Seronym